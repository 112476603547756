import './skip-link-focus-fix';
import AOS from 'aos';
import 'script-loader!slick-carousel';
import 'script-loader!lity';

(function($) {
	AOS.init({ once: true, duration: 500 });

	$(document).ready(function() {
		menuToggle();
		accordionShortcode();
		faqBlock();
		subNavigationToggle();
		leftAlignCardTextAccordion();
		featuredAttorneysAccordion();
		faqShortcode();
		videosBlockFilterDropdown();
		newsBlockFilterDropdown();
		resourcesBlockFilterDropdown();
	});

	// Header menu toggle
	function menuToggle() {
		$("#menu-toggle").on('click', function() {
			$(this).toggleClass('site-header__menu-toggle--open');
			$("#menu-primary-menu").toggleClass('site-header__menu--open');
		});

		$(".submenu-toggle").on('click', function() {
			var submenu = $(this).next('.sub-menu');
			$(this).toggleClass('submenu-toggle--open');
			submenu.slideToggle();
		});
	}

	// Accordion shortcode
	function accordionShortcode() {
		$('.accordion-shortcode__toggle').on('click', function () {
			var content = $(this).prev('.accordion-shortcode__content');

			content.slideToggle();
			$(this).toggleClass('accordion-shortcode__toggle--open');
		});
	}

	// FAQ shortcode
	function faqShortcode() {
		$(".faq-shortcode__heading").on('click', function(e) {
			e.preventDefault();

			var faq     = $(this).closest('.faq-shortcode'),
			    content = faq.find('.faq-shortcode__content');
				
			content.slideToggle(300);
			faq.toggleClass('faq-shortcode--open');
		});
	}

	// FAQ block
	function faqBlock() {
		$(".faq-block__heading").on('click', function(e) {
			e.preventDefault();

			var faq     = $(this).closest('.faq-block__faq'),
			    content = faq.find('.faq-block__content');
				
			content.slideToggle(300);
			faq.toggleClass('faq-block__faq--open');

			$('.faq-block__content').not(content).slideUp(300);
			$('.faq-block__faq').not(faq).removeClass('faq-block__faq--open');
		});
	}

	// Sub-navigation toggle
	function subNavigationToggle() {
		$('#sub-nav-toggle').on('click', function (e) {
			e.preventDefault();
			
			var popout = $('#sub-nav-popout');

			popout.toggleClass('sub-nav__popout--open');
			$(this).toggleClass('sub-nav__toggle--open');
		});
	}

	// Left align card text block accordion
	function leftAlignCardTextAccordion() {
		$('.left-card__accordion-toggle').on('click', function () {
			var content = $(this).parent().prev('.left-card__card').find('.left-card__accordion-content');

			content.slideToggle();
			$(this).toggleClass('left-card__accordion-toggle--open');
		});
	}

	// Featured attorneys block accordion
	function featuredAttorneysAccordion() {
		$('.featured-attorneys__accordion-toggle').on('click', function () {
			var content = $(this).parent().next('.featured-attorneys__accordion-content');

			content.slideToggle();
			$(this).toggleClass('featured-attorneys__accordion-toggle--open');
		});
	}

	// Videos block filters dropdown
	function videosBlockFilterDropdown() {
		$('.videos-block__filters-toggle').on('click', function () {
			var dropdown = $(this).next('.videos-block__filters-dropdown');

			dropdown.slideToggle();
			$(this).toggleClass('videos-bock__filters-toggle--open');
		});
	}

	// News block filters dropdown
	function newsBlockFilterDropdown() {
		$('.news-block__filters-toggle').on('click', function () {
			var dropdown = $(this).next('.news-block__filters-dropdown');

			dropdown.slideToggle();
			$(this).toggleClass('news-bock__filters-toggle--open');
		});
	}

	// Resources block filters dropdown
	function resourcesBlockFilterDropdown() {
		$('.resources-block__filters-toggle').on('click', function () {
			var dropdown = $(this).next('.resources-block__filters-dropdown');

			dropdown.slideToggle();
			$(this).toggleClass('resources-bock__filters-toggle--open');
		});
	}

})(jQuery);